<template>
<div class="uk-width-1-1">
  <div class="uk-background-default uk-padding-small">
    <div class="uk-form-stacked">
      <div class="uk-margin">
        <div class="uk-grid uk-child-width-1-3@m" uk-grid>
          <div>
            <label class="uk-form-label">Anzahl Auktionen</label>
            <div class="uk-grid-small uk-flex-middle uk-grid" uk-grid>
              <div class="uk-width-expand">
                <input class="uk-range" type="range" value="20" min="1" max="50" step="1" v-model="form.count">
              </div>
              <div class="uk-width-auto">
                <input type="text" class="uk-input uk-form-width-xsmall" v-model="form.count">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import 'vue-select/dist/vue-select.css'
export default {
  props: {
    data: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      form: {}
    }
  },
  mounted () {
    this.form = this.data
  }
}
</script>
